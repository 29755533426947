import React, { useEffect } from 'react';

type Props = {
  account?: string,
};

const TwitterButton = ({ account }: Props) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    document.getElementsByClassName('twitter-embed')[0].appendChild(script);
  }, []);

  const href = `https://twitter.com/${account}`;
  return (
    <div className="twitter-embed" style={{ height: 29 }}>
      <a href={href} data-size="large" data-show-screen-name="false"
         data-show-count="true" className="twitter-follow-button"></a>
    </div>
  );
};

export default TwitterButton;
