import React from "react";

import { useSiteMetadata } from "@/hooks";
import { TwitterButton } from "@/components/TwitterButton";

import * as styles from "./Author.module.scss";

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles.author}>
      <div className={styles.bio}>
        {author.bio}
        <div className={styles.twitter}>
          <strong>{author.name}</strong> 👇
          <TwitterButton account={author.contacts.twitter}/>
        </div>
      </div>
    </div>
  );
};

export default Author;
